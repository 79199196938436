.sec-title {
  position: relative;
  margin-bottom: 60px;
}

.sec-title .title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #fdda33;
  font-weight: 500;
  margin-bottom: 30px;
}

.sec-title h2 {
  position: relative;
  display: inline-block;
  font-size: 60px;
  line-height: 1em;
  color: #212639;
  font-weight: 400;
  padding-bottom: 30px;
  z-index: 2;
}

.sec-title.active h2:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.sec-title h2:after {
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 3px;
  width: 50px;
  content: "";
  background-color: #d1d2d6;
}

.sec-title.light h2 {
  color: #ffffff;
}

.sec-title.light h2:after {
  opacity: 0.2;
  background-color: #ffffff;
}

.sec-title.text-center h2:before,
.sec-title.text-center h2:after {
  right: 0;
  left: 0;
  margin: 0 auto;
}

.sec-title.text-center h2:before {
  opacity: 0.6;
}

.speakers-section {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* padding: 50px 0 90px; */
  overflow: hidden;
}

.speaker-block {
  position: relative;
  /* margin-bottom: 40px; */
}

.speaker-block .inner-box {
  position: relative;
}

.speaker-block .image-box {
  position: relative;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.15);
  overflow: hidden;
}

.speaker-block .image-box .image:after,
.speaker-block .image-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 100%;
  background-color: #ffffff;
  content: "";
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}

.speaker-block .inner-box:hover .image-box .image:after,
.speaker-block .inner-box:hover .image-box:before {
  height: 100%;
}

.speaker-block .image-box .image:after {
  opacity: 0.4;
  z-index: 1;
  top: auto;
  bottom: 0;
  background-color: #4a34a5;
}

.speaker-block .image-box .image {
  position: relative;
  margin-bottom: 0;
  z-index: 1;
  overflow: hidden;
}

.speaker-block .image-box .image img {
  display: block;
  width: 100%;
  height: auto;
}

.speaker-block .social-links {
  position: absolute;
  left: 0;
  bottom: -140px;
  padding: 20px;
  width: 100%;
  z-index: 9;
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}

.speaker-block .social-links ul {
  position: relative;
  padding: 20px 10px;
  text-align: center;
  background-color: #07032e;
}

.speaker-block .social-links li {
  position: relative;
  display: inline-block;
  margin: 0 9px;
}

.speaker-block .social-links li a {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block .social-links li a:hover {
  color: #4a34a5;
}

.speaker-block .inner-box:hover .social-links {
  bottom: 0;
}

.speaker-block .caption-box {
  position: relative;
  padding-top: 30px;
}

.speaker-block .caption-box .name {
  position: relative;
  display: block;
  font-size: 25px;
  line-height: 1em;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 10px;
}

.speaker-block .caption-box .name a {
  color: #ffffff;
  display: inline-block;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.speaker-block .caption-box .name a:hover {
  color: #4a34a5;
}

.speaker-block .caption-box .designation {
  position: relative;
  display: block;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  font-weight: 400;
  opacity: 0.7;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
