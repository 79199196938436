#quoteMark {
	width: 80px;
	filter: brightness(0) invert(1);
	margin-right: 20px;
	transform: translateY(-20%);
}
.diamond {
	width: 60px;
	height: 60px;
	border: 1px solid #fff;
	transform: rotateZ(45deg);
	margin-bottom: 20px;
	margin-top: 20px;
}
.icon {
	width: 40px;
	transform: rotateZ(-45deg) translate(-14%, 16%);
	filter: brightness(0) invert(1);
}

.subtitle {
	text-transform: uppercase;
	border-bottom: '1px solid #fff';
}
.divider {
	margin: 5px 0 30px;
	width: 50%;
	background-color: #fff;
	height: 2px;
}

.links {
	margin: 10px;
	/* text-decoration: none; */
	color: #fff;
	background-color: #55f;
	padding: 3px;
}
a.links:hover {
	/* text-decoration: none; */
	color: #ccf;
}

@media (min-width: 960px) {
	#posterIN {
		display: none;
	}
	#posterOUT {
		display: visible;
		position: relative;
		left: 15%
	}
}
@media (max-width: 960px) {
	#posterIN {
		display: visible;
		width: 50%;
	}
	#posterOUT {
		display: none;
	}
}
@media (max-width: 600px) {
	#posterIN {
		width: 75%;
	}
}
