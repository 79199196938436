@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Lobster&family=Patua+One&display=swap');

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* @font-face {
  font-family: "Ojol";
  src: url(./fonts/ojol/ojol.otf) format("opentype");
}

@font-face {
  font-family: "Kuvas";
  src: url(./fonts/kuvas/kuvas.otf) format("opentype");
}

@font-face {
  font-family: "Bord";
  src: url(./fonts/bord/bord.otf) format("opentype");
}

@font-face {
  font-family: "Fialiga";
  src: url(./fonts/fialiga/fialiga.otf) format("opentype");
}

@font-face {
  font-family: "Roadtest";
  src: url(./fonts/roadtest/roadtest.otf) format("opentype");
} */

.container-fluid {
  padding: 0 !important;
  margin: 0 !important;
}

.Toastify__toast--success{
  background-image: url(./assets/img/toast/toastBackground.jpg) !important;
  background-size:contain;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important ;
  font-size: 16px !important;
}   

.Toastify__close-button{
  color: white;
}

.Toastify__toast-icon{
  width: 35px !important;
}

@media screen and (min-width: 750px){
.Toastify__toast-container{
  width: 380px !important;
}
}
