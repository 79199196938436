@import url(https://fonts.googleapis.com/css2?family=Bree+Serif&family=Lobster&family=Patua+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.clock{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  
 

  /* background-image: linear-gradient(to right, #00b4db, #0083b0); */
  /* box-shadow: 0 0 1rem white; */
 /* justify-content: space-around;*/
}


.clockCard {
  margin-right:1.5rem;
  text-align: center;
  
  /* margin: 1em; */
  /* border: 2px solid white; */
  /*padding: 0.5em;

  text-align: center;
  /* border: 2px solid white; */
  /* border-right: 2px solid snow; */
  /*background-image: linear-gradient(to right, #00b4db, #0083b0);*/
}


@media (max-width: 700px) {
  .clock{
    display: flex;
    align-items: center;
    margin:auto;
    justify-content: center;
    
  }
  
  .clockCard{
    width: 80%;
    margin-right: 1rem;
    /*background-image: linear-gradient(to right, #00b4db, #0083b0);*/

  }
 
  .fix{
    font-size: "12px";
  }
}


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* @font-face {
  font-family: "Ojol";
  src: url(./fonts/ojol/ojol.otf) format("opentype");
}

@font-face {
  font-family: "Kuvas";
  src: url(./fonts/kuvas/kuvas.otf) format("opentype");
}

@font-face {
  font-family: "Bord";
  src: url(./fonts/bord/bord.otf) format("opentype");
}

@font-face {
  font-family: "Fialiga";
  src: url(./fonts/fialiga/fialiga.otf) format("opentype");
}

@font-face {
  font-family: "Roadtest";
  src: url(./fonts/roadtest/roadtest.otf) format("opentype");
} */

.container-fluid {
  padding: 0 !important;
  margin: 0 !important;
}

.Toastify__toast--success{
  background-image: url(/static/media/toastBackground.c87e908d.jpg) !important;
  background-size:contain;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important ;
  font-size: 16px !important;
}   

.Toastify__close-button{
  color: white;
}

.Toastify__toast-icon{
  width: 35px !important;
}

@media screen and (min-width: 750px){
.Toastify__toast-container{
  width: 380px !important;
}
}

/* footer {
  padding-top: 15px;
  /* padding-bottom: 0px; */
/* color: #fff;
  font-size: 18px;
  text-align: center; */
/* position: relative; */
/* background: rgb(0, 0, 0); */
/* background-color: #262626;
} */
/* 
.last {
  padding-top: 15px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 18px;
  /* text-align: center; */
/* position: relative; */
/* background-color: #262626; */
/* background: rgb(0, 0, 0); */
/* } */

/* .row {
  text-align: center;
  justify-content: center;
}

.columns {
  text-align: center;
  width: 80%;
} */

/* footer a,
footer a:visited {
  color: #fff;
}
footer a:hover,
footer a:focus {
  color: #fff;
} */

/* copyright */
/* footer .copyright {
  margin: 0;
  padding: 0;
}
footer .copyright li {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 24px;
}
.ie footer .copyright li {
  display: inline;
}
footer .copyright li:before {
  content: "\2022";
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
}
footer .copyright li:first-child:before {
  display: none;
} */

/* social links */
/* footer .social-links {
  margin: 18px 0 15px 0;
  padding: 0;
  font-size: 30px;
}
footer .social-links li {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 42px;
  color: #ffffff;
}

footer .social-links li:first-child {
  margin-left: 0;
}*/

a .rotate {
  font-size: 1.3em;
  float: left;
  margin: 3px 5px;
  transition: 0.2s ease-out;
}

a:hover .rotate {
  color: blue;
  -webkit-transform: rotateZ(3600deg);
  transform: rotateZ(3600deg);
}

@media only screen and (max-width: 600px) {
  .about-heading {
    text-align: center;
  }
}

.counter_wrapper {
  width: 70%;
  padding-top: 20px;
  padding-bottom: 100px;
  font-family: "Poppins", sans-serif;
}
.counter_wrapper .count_box {
  padding: 25px 10px;
  border: 2px solid white;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;
}
.counter_wrapper .count_box h3 {
  font-size: 40px;
  font-weight: 600;
  color: white;
  transition: all 0.5s;
}
.counter_wrapper .count_box h4 {
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  padding-top: 10px;
  transition: all 0.5s;
}
.counter_wrapper .box_center h3,
.counter_wrapper .box_center h4 {
  color: #fff;
  transition: all 0.5s;
}
.counter_wrapper .box_hover:hover {
  transition: all 0.5s ease;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.counter_wrapper .box_hover:hover h3,
.box_hover:hover h4 {
  color: #fff;
}

.responsive_counter_wrapper {
  padding: 0;
}

.small_screen_wrapper {
  padding: 0;
}

@media (max-width: 768px) {
  .counter_wrapper .col-md-6 {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .responsive_counter_wrapper {
    display: none;
  }
}

@media (min-width: 768px) {
  .small_screen_wrapper {
    display: none;
  }
}

.divider {
  margin: 5px 0 5px;
  width: 50%;
  background-color: #fff;
  height: 2px;
}

@media (min-width: 960px) {
  #trophyup {
    display: none;
  }
  #trophymiddle {
    display: visible;
  }
  /* #title {
    font-size: 35px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 60px;
    padding-bottom: 20px;
  } */
}
@media (max-width: 960px) {
  #trophyup {
    display: visible;
    width: 40%;
  }
  #trophymiddle {
    display: none;
  }
}
@media (max-width: 600px) {
  #trophyup {
    display: visible;
    width: 50%;
    /* margin-top: 10px; */
    margin-bottom: 40px;
  }
  /* #title {
    font-size: 30px;
    margin-bottom: 20px;
  } */
}

.testimonial-slider {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

@media (max-width: 700px) {
  .testimonial-slider {
    height: 50vh;
  }
}

@media (max-width: 900px) {
  .testimonial-slider {
    height: 60vh;
  }
}

.slide {
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.active-anim {
  display: block;
  opacity: 1;
}
.inactive-anim {
  display: none;
}
.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
}
.prev {
  left: -30%;
  top: 40%;

  
}
.next {
  right: -30%;
  top: 40%;
  
  
}
#regbutton{
  margin-right:12px ;
  
}
#downbtn{
  margin-left:12px;
}

@media (max-width: 1250px) {
  .prev {
    left: -10%;
  }
  .next {
    right: -10%;
  }
  .btn-slide {
    height: 40px;
    width: 40px;
  }
  
 
}

@media (max-width: 700px) {
  .prev {
   
    top: 90%;
    margin-left: 2.5rem;
    margin-bottom: 2rem;
  }
  .next {
    
    top: 90%;
    margin-right: 2.5rem;
    margin-bottom: 2rem;
  }
  .btn-slide {
    height: 30px;
    width: 30px;
  }
  #regbutton{
    margin-right:0 ;
    margin-bottom: 12px;
    
  }
  #downbtn{
    margin-left:0;
    margin-top: 12px;
  }
 
}


/*information css*/

.card_testimonial {
  background-color: #0d1117;
  width: 800px;
  box-shadow: 2px 2px 5px #54708a, -1px -1px 5px #54708a;
  border-radius: 3px;
  display: grid;
  padding: 20px;
}

.card_testimonial .img-container_testimonial {
  width: 230px;
  height: 100%;
  grid-column: 2;
  background-color: #c1b7b4;
  background-image: url("https://www.dropbox.com/s/7d5qt5wb2xpqeww/city-street.jpg?raw=1");
  background-size: cover;
  background-position: center center;
}

.card-content_testimonial {
  /*grid-column: 3 / 5;*/
  padding: 10px 30px;
  /*width:800px;*/
}

.card-content_testimonial h2 {
  margin-bottom: 20px;
  color: white;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.card-content_testimonial h1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 20px;
  color: white;
  font-size: 27px;
}
.card-content_testimonial .author_testimonial {
  font-weight: 700;
  margin-top: 11px;
  /*  padding: 10px 0 10px 0; */
  color: #3185e3;
}


.feedback_testimonial {
  color: #6b746a;
  font-size: 17.5px;
  

}

@media (max-width: 1200px) {
  .card_testimonial {
    width: 750px;
  }
  .card-content_testimonial h2 {
    font-size: 13px;
  }
  .feedback_testimonial{
    font-size: 17.5px;
  }


}

@media (max-width: 1000px) {
  .card_testimonial {
    width: 600px;
  }
  .card-content_testimonial h2 {
    font-size: 13px;
  }
  .feedback_testimonial{
    font-size: 17px;
  }
  #fybtn{
    display:block;
    text-align: center;
  }
  #regbutton{
    margin-right:0 ;
    margin-bottom: 12px;
    
  }
  #downbtn{
    margin-left:0;
    margin-top: 12px;
  }

}

@media (max-width: 800px) {
  .card_testimonial {
    width: 530px;
  }
  .card-content_testimonial h2 {
    font-size: 13px;
  }
  .feedback_testimonial{
    font-size: 17px;
  }
  
  
  
 
}

@media (max-width: 700px) {
  .card_testimonial {
    display: block;
    width: 350px;
  }
  .card_testimonial .img-container_testimonial {
    height: 250px;
    width: 100%;
  }
  .card-content_testimonial {
    border: 0;
  }
  .card-content_testimonial h2 {
    font-size: 13px;
  }
  .feedback_testimonial{
    font-size: 15px;
  }
 
  

}

@media (max-width: 570px) {
  .card_testimonial {
    display: block;
    width: 300px;
  }
  .card-content_testimonial h2 {
    font-size: 13px;
  }
  .feedback_testimonial{
    font-size: 15px;
  }
 
  
 
}

#quoteMark {
	width: 80px;
	-webkit-filter: brightness(0) invert(1);
	        filter: brightness(0) invert(1);
	margin-right: 20px;
	-webkit-transform: translateY(-20%);
	        transform: translateY(-20%);
}
.diamond {
	width: 60px;
	height: 60px;
	border: 1px solid #fff;
	-webkit-transform: rotateZ(45deg);
	        transform: rotateZ(45deg);
	margin-bottom: 20px;
	margin-top: 20px;
}
.icon {
	width: 40px;
	-webkit-transform: rotateZ(-45deg) translate(-14%, 16%);
	        transform: rotateZ(-45deg) translate(-14%, 16%);
	-webkit-filter: brightness(0) invert(1);
	        filter: brightness(0) invert(1);
}

.subtitle {
	text-transform: uppercase;
	border-bottom: '1px solid #fff';
}
.divider {
	margin: 5px 0 30px;
	width: 50%;
	background-color: #fff;
	height: 2px;
}

.links {
	margin: 10px;
	/* text-decoration: none; */
	color: #fff;
	background-color: #55f;
	padding: 3px;
}
a.links:hover {
	/* text-decoration: none; */
	color: #ccf;
}

@media (min-width: 960px) {
	#posterIN {
		display: none;
	}
	#posterOUT {
		display: visible;
		position: relative;
		left: 15%
	}
}
@media (max-width: 960px) {
	#posterIN {
		display: visible;
		width: 50%;
	}
	#posterOUT {
		display: none;
	}
}
@media (max-width: 600px) {
	#posterIN {
		width: 75%;
	}
}

#picCards {
  width: 100%;
  background-color: #040534ab;
  color: white;
}

#FocusCard {
  width: 100%;
  /* max-height: 100%; */
  height: 250px;
  /* object-fit: cover; */
  background-color: #ffffff2b;
  color: black;
}

button.rec-dot {
  display: none;
}

button.rec-arrow {
  background-color: rgb(0 0 0 / 34%);
  color: #fff;
}

.sec-title {
  position: relative;
  margin-bottom: 60px;
}

.sec-title .title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #fdda33;
  font-weight: 500;
  margin-bottom: 30px;
}

.sec-title h2 {
  position: relative;
  display: inline-block;
  font-size: 60px;
  line-height: 1em;
  color: #212639;
  font-weight: 400;
  padding-bottom: 30px;
  z-index: 2;
}

.sec-title.active h2:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sec-title h2:after {
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 3px;
  width: 50px;
  content: "";
  background-color: #d1d2d6;
}

.sec-title.light h2 {
  color: #ffffff;
}

.sec-title.light h2:after {
  opacity: 0.2;
  background-color: #ffffff;
}

.sec-title.text-center h2:before,
.sec-title.text-center h2:after {
  right: 0;
  left: 0;
  margin: 0 auto;
}

.sec-title.text-center h2:before {
  opacity: 0.6;
}

.speakers-section {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* padding: 50px 0 90px; */
  overflow: hidden;
}

.speaker-block {
  position: relative;
  /* margin-bottom: 40px; */
}

.speaker-block .inner-box {
  position: relative;
}

.speaker-block .image-box {
  position: relative;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.15);
  overflow: hidden;
}

.speaker-block .image-box .image:after,
.speaker-block .image-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 100%;
  background-color: #ffffff;
  content: "";
  transition: all 700ms ease;
}

.speaker-block .inner-box:hover .image-box .image:after,
.speaker-block .inner-box:hover .image-box:before {
  height: 100%;
}

.speaker-block .image-box .image:after {
  opacity: 0.4;
  z-index: 1;
  top: auto;
  bottom: 0;
  background-color: #4a34a5;
}

.speaker-block .image-box .image {
  position: relative;
  margin-bottom: 0;
  z-index: 1;
  overflow: hidden;
}

.speaker-block .image-box .image img {
  display: block;
  width: 100%;
  height: auto;
}

.speaker-block .social-links {
  position: absolute;
  left: 0;
  bottom: -140px;
  padding: 20px;
  width: 100%;
  z-index: 9;
  transition: all 700ms ease;
}

.speaker-block .social-links ul {
  position: relative;
  padding: 20px 10px;
  text-align: center;
  background-color: #07032e;
}

.speaker-block .social-links li {
  position: relative;
  display: inline-block;
  margin: 0 9px;
}

.speaker-block .social-links li a {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #ffffff;
  transition: all 300ms ease;
}

.speaker-block .social-links li a:hover {
  color: #4a34a5;
}

.speaker-block .inner-box:hover .social-links {
  bottom: 0;
}

.speaker-block .caption-box {
  position: relative;
  padding-top: 30px;
}

.speaker-block .caption-box .name {
  position: relative;
  display: block;
  font-size: 25px;
  line-height: 1em;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 10px;
}

.speaker-block .caption-box .name a {
  color: #ffffff;
  display: inline-block;
  transition: all 300ms ease;
}

.speaker-block .caption-box .name a:hover {
  color: #4a34a5;
}

.speaker-block .caption-box .designation {
  position: relative;
  display: block;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  font-weight: 400;
  opacity: 0.7;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}

#faqBox {
  box-shadow: 0 0 4px #ccc;
}

@media only screen and (max-width: 768px) {
  .grid-item {
    overflow-x: hidden;
  }
}

