body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.clock{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  
 

  /* background-image: linear-gradient(to right, #00b4db, #0083b0); */
  /* box-shadow: 0 0 1rem white; */
 /* justify-content: space-around;*/
}


.clockCard {
  margin-right:1.5rem;
  text-align: center;
  
  /* margin: 1em; */
  /* border: 2px solid white; */
  /*padding: 0.5em;

  text-align: center;
  /* border: 2px solid white; */
  /* border-right: 2px solid snow; */
  /*background-image: linear-gradient(to right, #00b4db, #0083b0);*/
}


@media (max-width: 700px) {
  .clock{
    display: flex;
    align-items: center;
    margin:auto;
    justify-content: center;
    
  }
  
  .clockCard{
    width: 80%;
    margin-right: 1rem;
    /*background-image: linear-gradient(to right, #00b4db, #0083b0);*/

  }
 
  .fix{
    font-size: "12px";
  }
}

