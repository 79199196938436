#picCards {
  width: 100%;
  background-color: #040534ab;
  color: white;
}

#FocusCard {
  width: 100%;
  /* max-height: 100%; */
  height: 250px;
  /* object-fit: cover; */
  background-color: #ffffff2b;
  color: black;
}

button.rec-dot {
  display: none;
}

button.rec-arrow {
  background-color: rgb(0 0 0 / 34%);
  color: #fff;
}
