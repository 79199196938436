.testimonial-slider {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

@media (max-width: 700px) {
  .testimonial-slider {
    height: 50vh;
  }
}

@media (max-width: 900px) {
  .testimonial-slider {
    height: 60vh;
  }
}

.slide {
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.active-anim {
  display: block;
  opacity: 1;
}
.inactive-anim {
  display: none;
}
.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
}
.prev {
  left: -30%;
  top: 40%;

  
}
.next {
  right: -30%;
  top: 40%;
  
  
}
#regbutton{
  margin-right:12px ;
  
}
#downbtn{
  margin-left:12px;
}

@media (max-width: 1250px) {
  .prev {
    left: -10%;
  }
  .next {
    right: -10%;
  }
  .btn-slide {
    height: 40px;
    width: 40px;
  }
  
 
}

@media (max-width: 700px) {
  .prev {
   
    top: 90%;
    margin-left: 2.5rem;
    margin-bottom: 2rem;
  }
  .next {
    
    top: 90%;
    margin-right: 2.5rem;
    margin-bottom: 2rem;
  }
  .btn-slide {
    height: 30px;
    width: 30px;
  }
  #regbutton{
    margin-right:0 ;
    margin-bottom: 12px;
    
  }
  #downbtn{
    margin-left:0;
    margin-top: 12px;
  }
 
}


/*information css*/

.card_testimonial {
  background-color: #0d1117;
  width: 800px;
  box-shadow: 2px 2px 5px #54708a, -1px -1px 5px #54708a;
  border-radius: 3px;
  display: grid;
  padding: 20px;
}

.card_testimonial .img-container_testimonial {
  width: 230px;
  height: 100%;
  grid-column: 2;
  background-color: #c1b7b4;
  background-image: url("https://www.dropbox.com/s/7d5qt5wb2xpqeww/city-street.jpg?raw=1");
  background-size: cover;
  background-position: center center;
}

.card-content_testimonial {
  /*grid-column: 3 / 5;*/
  padding: 10px 30px;
  /*width:800px;*/
}

.card-content_testimonial h2 {
  margin-bottom: 20px;
  color: white;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.card-content_testimonial h1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 20px;
  color: white;
  font-size: 27px;
}
.card-content_testimonial .author_testimonial {
  font-weight: 700;
  margin-top: 11px;
  /*  padding: 10px 0 10px 0; */
  color: #3185e3;
}


.feedback_testimonial {
  color: #6b746a;
  font-size: 17.5px;
  

}

@media (max-width: 1200px) {
  .card_testimonial {
    width: 750px;
  }
  .card-content_testimonial h2 {
    font-size: 13px;
  }
  .feedback_testimonial{
    font-size: 17.5px;
  }


}

@media (max-width: 1000px) {
  .card_testimonial {
    width: 600px;
  }
  .card-content_testimonial h2 {
    font-size: 13px;
  }
  .feedback_testimonial{
    font-size: 17px;
  }
  #fybtn{
    display:block;
    text-align: center;
  }
  #regbutton{
    margin-right:0 ;
    margin-bottom: 12px;
    
  }
  #downbtn{
    margin-left:0;
    margin-top: 12px;
  }

}

@media (max-width: 800px) {
  .card_testimonial {
    width: 530px;
  }
  .card-content_testimonial h2 {
    font-size: 13px;
  }
  .feedback_testimonial{
    font-size: 17px;
  }
  
  
  
 
}

@media (max-width: 700px) {
  .card_testimonial {
    display: block;
    width: 350px;
  }
  .card_testimonial .img-container_testimonial {
    height: 250px;
    width: 100%;
  }
  .card-content_testimonial {
    border: 0;
  }
  .card-content_testimonial h2 {
    font-size: 13px;
  }
  .feedback_testimonial{
    font-size: 15px;
  }
 
  

}

@media (max-width: 570px) {
  .card_testimonial {
    display: block;
    width: 300px;
  }
  .card-content_testimonial h2 {
    font-size: 13px;
  }
  .feedback_testimonial{
    font-size: 15px;
  }
 
  
 
}
