@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");
.counter_wrapper {
  width: 70%;
  padding-top: 20px;
  padding-bottom: 100px;
  font-family: "Poppins", sans-serif;
}
.counter_wrapper .count_box {
  padding: 25px 10px;
  border: 2px solid white;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;
}
.counter_wrapper .count_box h3 {
  font-size: 40px;
  font-weight: 600;
  color: white;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.counter_wrapper .count_box h4 {
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  padding-top: 10px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.counter_wrapper .box_center h3,
.counter_wrapper .box_center h4 {
  color: #fff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.counter_wrapper .box_hover:hover {
  transition: all 0.5s ease;
  transform: scale(1.05);
}
.counter_wrapper .box_hover:hover h3,
.box_hover:hover h4 {
  color: #fff;
}

.responsive_counter_wrapper {
  padding: 0;
}

.small_screen_wrapper {
  padding: 0;
}

@media (max-width: 768px) {
  .counter_wrapper .col-md-6 {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .responsive_counter_wrapper {
    display: none;
  }
}

@media (min-width: 768px) {
  .small_screen_wrapper {
    display: none;
  }
}
