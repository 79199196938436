.divider {
  margin: 5px 0 5px;
  width: 50%;
  background-color: #fff;
  height: 2px;
}

@media (min-width: 960px) {
  #trophyup {
    display: none;
  }
  #trophymiddle {
    display: visible;
  }
  /* #title {
    font-size: 35px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 60px;
    padding-bottom: 20px;
  } */
}
@media (max-width: 960px) {
  #trophyup {
    display: visible;
    width: 40%;
  }
  #trophymiddle {
    display: none;
  }
}
@media (max-width: 600px) {
  #trophyup {
    display: visible;
    width: 50%;
    /* margin-top: 10px; */
    margin-bottom: 40px;
  }
  /* #title {
    font-size: 30px;
    margin-bottom: 20px;
  } */
}
